@import './../../../stylesheet/variables';

.commandEditor {
	border: $tp-panelBorderColor solid 1px;
	border-radius: 5px;
	background-color: #f6f6f6;
	padding: 3px 5px;

	.action {
		float: left;
		overflow: hidden;
		width: 132px;
		line-height: calc(#{$iconSmallHeight} / 2);
		text-align: center;

		label {
			display: block;
		}

		span {
			padding: 9px 3px;
			font-size: 25px;
		}
	}

	.basicValues {
		display: inline-block;
		width: 3*36px + 8px;
		height: $iconSmallHeight;
		line-height: calc(#{$iconSmallHeight} - 6px);
		border: $tp-panelBorderColor solid 1px;
		background-color: $tp-iconBg;
		border-radius: 10px;
		padding: 3px;
		margin: 1px;
		color: $tp-panelGrayColor;
		font-size: 10px;


		.switch {
			width: 36px;
			height: 36px;
			line-height: 32px;
			text-align: center;
			font-size: 20px;
			color: black;

			span {
				background-color: transparent;
			}
		}

		div {
			float: left;
			padding: 2px;
			border-radius: 3px;
		}

		input {
			width: 70px;
		}

		.sel {
			background-color: $tp-selected;
		}
	}

	.timedParameter {
		color: $tp-panelGrayColor;
		font-size: 10px;
		margin: 2px;

		input {
			width: 60px;
		}
	}
}

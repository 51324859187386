.centerDiv {
	.unit-values {
		padding: 2px 0;

		.fa-regular {
			width: 20px;
			text-align: right;
		}
	}
}

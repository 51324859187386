@import 'variables';

$border: 1px solid rgba(0, 0, 0, 0.07);

.m-week {
	color: $color-white;

	button.btn-repeat {
		margin: 5px;
	}

	button {
		width: 40%;
		text-align: center;
  }

  table {
		color: rgba(0, 0, 0, 0.20);
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		table-layout: fixed;

		td {
			font-size: 12px;
			padding: 8px 0;
			text-align: center;
			color: #666666;
		}

		td.head {
			font-weight: 700;
			text-transform: uppercase;
		}

		td.available {
			border: $border;

			&:hover {
				background: $tp-control-active;
				border-color: $tp-control-active;
				color: $sliderDisabled;
			}
		}

		td.selected {
			border: $border;
			background: $sliderDisabled;
			color: $color-white;

			&:hover {
				background: $tp-control-active;
				border-color: $tp-control-active;
				color: $color-white;
			}
		}
	}

	.m-buttons {
		text-align: center;
	}

	.clickable {
		cursor: pointer;
	}
}

.m-week-active {
	table {
		color: $color-highlighted;

		td.selected {
			border: $border;
			background: $color-highlighted;
			color: $color-white;
		}

		td.available {
			border: $border;

			&:hover {
				background: $tp-control-active;
				border-color: $tp-control-active;
				color: $sliderDisabled;
			}
		}
	}
}

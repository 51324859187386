@import './../../stylesheet/variables';

$labelWidth: 70px;

.ControlEditor {
	width: 100%;
	height: $iconContainerSize + 5px;

	.content {
		float: left;
		width: calc(100% - $iconContainerSizeWithMargin);
		height: $iconContainerSize;

		.basic-icons {
			height: 40px;
			width: 100%;

			.item {
				width: 26px;
				height: 20px;
				float: left;
				padding: 1px;
			}

			.item-double {
				width: 42px;
				height: 20px;
				float: left;
				padding: 1px;

				.units {
					font-size: 8px;
					padding-bottom: 10px;
				}
			}

			.switched-on {
				border-radius: 4px;
				background-color: $tp-panelIconBackground;
				color: $tp-control-active;
			}
		}

		.sel {
			border-radius: 4px;
			background-color: $tp-panelIconBackground;
		}
	}
}

.ControlPicker {
	display: inline-block;
	width: $iconContainerSizeWithBorder;
	height: $iconContainerSizeWithTitle;
	margin: 1px;
	padding: 0 1px;
	border: $tp-panelGrayColor solid 1px;
	border-radius: 10px;
	cursor: pointer;

	.picker-icon, .picker-clear {
		color: $tp-panelIcon;
		display: inline-block;
		width: 20px;
		height: $iconContainerTitle;
		padding-top: 3px;
		font-size: 16px;
	}

	.picker-icon {
		float: left;
	}

	.picker-clear {
		float: right;
	}

	.picker-title {
		color: $tp-panelIcon;
		display: inline-block;
		width: $iconContainerSizeWithBorder - 45px;
		height: $iconContainerTitle;
		font-size: 10px;
		overflow: hidden;
	}

	.picker-plus {
		float: left;
		width: $iconContainerSizeWithBorder - 5px;
		font-size: 20px;
		line-height: $iconNameSize;
	}
}



@import './../../stylesheet/variables';

.Control {
	font-size: 32px;
	cursor: pointer;
}

.faActive {
	color: $tp-control-active;
}

.faHot {
	color: $tp-control-hot;
}

.faCold {
	color: $tp-control-cold;
}

.visualControl {
	position: absolute;
}

.containerControl {
	margin: 7px 31px;
}

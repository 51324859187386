@import './../../stylesheet/variables';

.TopPanel {
	padding: 5px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 120px;
	backdrop-filter: blur(4px);
	background-color: rgba(230, 230, 230, 0.60);
	border-bottom: 1px $tp-panelBorderColor solid;
	font-family: "Lato", arial, sans-serif;

	.menuCaption {
		padding: 10px 4px;
		font-size: 18px;
	}
}



@import 'variables';
@import 'slider';
@import 'time';
@import 'week';
@import url(fontAwesome/fontawesome.scss);
@import url(fontAwesome/solid.scss);
@import url(fontAwesome/regular.scss);
@import url(fontAwesome/v4-shims.scss);
@import url(allura.css);

* {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

body {
	background: white;
	margin: 0;
	padding: 0;
}

.layout {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	text-align: center;
	padding: 80px;
	font-family: 'Lato', arial, sans-serif;
	font-size: 50px;
	z-index: 10000;
}

h1.login {
	color: #ccc;
	text-align: center;
	font-family: 'Allura', cursive;
	font-size: 50px;
}

.login-form {
	width: 350px;
	padding: 40px 30px;
	background: $tp-panelBg;
	border-radius: 4px;
	margin: auto;
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

.form-group {
	position: relative;
	margin-bottom: 15px;
}

.form-row {
	width: 100%;
	height: 25px;
	padding: 5px 7px 5px 15px;
	display: block;
}

.form-control {
	width: 100%;
	height: 50px;
	padding: 5px 7px 5px 15px;
	background: #fff;
	color: #666;
	border: 2px solid #ddd;
	border-radius: 4px;

	&:focus, &:focus + .fa-regular {
		border-color: $tp-active;
		color: $tp-active;
	}
}

.form-group .fa-regular {
	position: absolute;
	right: 15px;
	top: 17px;
	color: #999;
}

.log-status.wrong-entry {
	animation: wrong-log 0.3s;
}

.log-status.wrong-entry .form-control, .wrong-entry .form-control + .fa-regular {
	border-color: #ed1c24;
	color: #ed1c24;
}

.log-btn {
	background: $tp-active;
	display: inline-block;
	width: 100%;
	font-size: 16px;
	height: 50px;
	color: #fff;
	text-decoration: none;
	border: none;
	border-radius: 4px;
}

.log-btn:disabled {
	background: #C6C6C6;
}

.link {
	text-decoration: none;
	color: #C6C6C6;
	float: right;
	font-size: 12px;
	margin-bottom: 15px;
	&:hover {
		text-decoration: underline;
		color: #8C918F;
	}
}

.alert {
	font-size: 12px;
	color: #f00;
	float: left;
}

/////loader:
.loader,
.loader:before,
.loader:after {
	background: #ffffff;
	-webkit-animation: load1 1s infinite ease-in-out;
	animation: load1 1s infinite ease-in-out;
	width: 1em;
	height: 4em;
	color: $tp-active;
}

.loader {
	color: #ffffff;
	text-indent: -9999em;
	margin: 88px auto;
	position: relative;
	font-size: 11px;
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

.loader:before,
.loader:after {
	position: absolute;
	top: 0;
	content: '';
}

.loader:before {
	left: -1.5em;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.loader:after {
	left: 1.5em;
}

@-webkit-keyframes load1 {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}

@keyframes load1 {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}

.caps {
	text-transform: uppercase;
}

/// touchpanel

.mainView {
	padding: 0;
}

.mainView {
	font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	width: 100%;
	height: 100%;
	margin: 0;

	vertical-align: top;
	color: $tp-panelIcon;

	h1 {
		margin: 0 auto;
		text-align: center;
		font-size: 24px;
		line-height: 50px;
	}

	section {
		overflow: auto;
		}

	.context {
		position: fixed;
		top: 0;
		right: 0;
		color: $tp-fontDark;
		width: $contextWidth + 11px;
		height: 100%;
		border-left: 1px solid $tp-panelBorderColor;
		background-color: $tp-panelBg;
		vertical-align: top;
		font-size: 14px;
		padding: 110px 0 0 0;
		z-index: 10;

		.menu {
			margin-top: -110px;
		}

		.contextBox {
			width: $contextWidth;
			overflow: auto;
			height: 100%;

		}

		.selectFa {
			font-size: 48px;
			text-align: center;

			.fa-regular {
				padding: 3px;
				border-radius: 3px;
			}
		}

		.select-units {
			text-align: center;

			.fa-regular {
				padding: 3px;
				border-radius: 3px;
			}
		}

		.disabled {
			color: $tp-panelGrayColor;
		}

		span.field {
			padding: 0 4px;
			font-size: 11px;
		}

		.icon-main-context {
			float: left;
			height: $iconContainerSize;
			width: $contextWidth - ($iconContainerSize + 2px);
			padding: 0 0 0 5px;

			.title {
				padding-top: 3px;
				padding-left: 2px;
				padding-right: 2px;
				height: $sectionTitleHeight - 5px;
				line-height: $sectionTitleHeight - 10px;
				font-weight: bold;
				font-size: 13px;

				span {
					float: right;
					margin-right: 8px;
				}
			}
		}
		.icon-sub-context {
			float: left;
			width: $contextWidth;
			padding: 5px 0 0 0;

			.item {
				float: left;
				width: 32px + 4px;
				height: 2 * 32px + 4px;
				padding: 2px;
				border-radius: 5px;
			}

			.types {
				width: $contextWidth;
				height: 2 * 32px + 4px + 8px;
				padding: 4px 2px;
			}

			.sel {
				background-color: $tp-selected;
			}
		}

		section.icon {
			// height: $iconContainerSize + $sectionTitleHeight + 2px;


		}
		.sectionBox {
			float: left;
			width: $contextWidth;
		}

		.sectionTitle {
			width: $contextWidth;
			height: $sectionTitleHeight;
			line-height: $sectionTitleHeight - 5px;
			padding-top: 5px;
			font-weight: bold;
		}
		.sectionTitle {
			float: left;
			color: $tp-panelColor;
			text-align: center;

			button {
				float: right;
			}

			span.fa-regular {
				float: right;
				font-size: 18px;
				margin: 4px;
				cursor: pointer;
			}
		}
		.subsectionTitle {
			padding-left: 2px;
			padding-right: 2px;
			height: $sectionTitleHeight;
			line-height: $sectionTitleHeight - 5px;
			padding-top: 5px;
			font-weight: bold;

			input {
				float: right;
				margin: 2px 0 0 0;
			}

			label {
				float: right;
				input {
					float: none;
				}
			}
		}
		.subsectionText {
			padding-left: 5px;
			padding-right: 5px;
			line-height: $sectionTitleHeight - 10px;
			color: $tp-panelColor;
			font-size: 12px;
		}
		.sectionButtons {
			min-height: 1.5 * $sectionTitleHeight;
			padding: 4px;
			width: $contextWidth;
			text-align: center;
			float: left;

			button {
				font-size: 13px;
				margin: 3px;
			}
		}
		.sectionField {
			float: left;
			width: $contextWidth;
			height: 42px;
			padding-top: 5px;
			font-weight: bold;

			input {
				width: $contextWidth;
			}
		}

		label {
			font-weight: normal;
		}

		.form {
			width: $contextWidth;
			border-spacing: 5px 2px;
			border: none;

			.field {
				vertical-align: top;
				font-weight: bold;
			}

			.value {
				vertical-align: top;
				width: 100%;
				input {
					width: 100%;
				}

				select {
					width: 100%;
				}
			}
		}
	}
}

.unit-item {
	background-color: white;
	border-radius: 5px;
	padding: 8px;
	margin: 2px;
	line-height: 35px;
	cursor: pointer;
}

$tp-iconSize: 32px;
.ib_on, .ib_off {
	width: $tp-iconSize;
	height: $tp-iconSize;
	background-size: $tp-iconSize;
}

.ib_on {
	background-position: 0 -3*$tp-iconSize;
}

.ib_off {
	background-position: 0 -2*$tp-iconSize;
}

.none {
	background-image: url("../img/none.png");
}

.light {
	background-image: url("../img/light.png");
}

.athome {
	background-image: url("../img/athome.png");
}

.fan {
	background-image: url("../img/fan.png");
}

.heat {
	background-image: url("../img/heat.png");
}

.night {
	background-image: url("../img/night.png");
}

.run {
	background-image: url("../img/run.png");
}

.visualIcons {
	position: absolute;
	touch-action: none;
	z-index: 10;

	.ib_on, .ib_off {
		position: absolute;
	}

	.sel {
		//background-color: $tp-selected;
		color: $tp-selected;
		border-radius: 3px;
		cursor: move;
	}

	.valueSelector {
		position: absolute;

		.arrows {
			display: block;
		}
	}

	.thermostat {
		position: absolute;
	}
}

.valueSelector {
	cursor: default;
	font-size: 13px;
	line-height: 13px;
	padding: 5px;
	text-align: center;

	.fa, .value, .button {
		height: 19px;
		padding: 3px;
		margin: 3px 0;
		line-height: 13px;
		text-align: left;
	}
	.value {
		display: inline-block;
		width: 60px;
	}
	.on, .off, .auto, .button {
		background-color: $tp-panelBg;
		color: black;
		padding: 3px;
		text-align: center;
	}
	.auto {
		border-radius: 0;
	}
	.on {
		border-radius: 8px 0 0 8px;
		padding-left: 8px;
	}
	.off {
		border-radius: 0 8px 8px 0;
		padding-right: 8px;
	}

	.button {
		border-radius: 8px;
		padding-left: 8px;
		padding-right: 8px;
		margin: 0 2px;
	}

	.buttons {
		padding: 8px 0;
	}

	.sel {
		background-color: $tp-active;
		color: $tp-active-inverted;
	}
}

$tp-moduleWidth: 250px;
$tp-moduleHeight: 275px;
$userModuleHeight: 125px;
.modules {
	.moduleContainer {
		margin: 0 5px 10px 5px;
		width: $tp-moduleWidth;
		overflow: hidden;
		border: $tp-panelBorderColor 1px solid;
	}

	.sel {
		.sel {
			background-color: $tp-panelBg;
		}

		.blockName {
			color: $tp-fontSelectedDark;
		}
	}

	.moduleSlots {
		width: $tp-moduleWidth + 10px;
		float: left;
	}

	.newModule {
		line-height: 30px;
		text-align: center;
		padding: 5px;

		span {
			font-size: 11px;
			border-radius: 5px;
			padding: 8px;
			margin: $tp-buttonMargin;
			border: $tp-panelIconBackground 1px solid;
			cursor: pointer;
		}
	}
}

.iconContainer, .iconSmallContainer {
	float: left;
}

.iconContainer, .iconSmallContainer, .moduleContainer {
	overflow: hidden;
	background-color: $tp-iconBg;

	.iconName {
		float: left;
		overflow: hidden;
		width: $iconNameSize;
		position: relative;
		color: $tp-fontDark;
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}


.iconContainer, .iconSmallContainer {
	margin: 1px;
	width: $iconContainerSize;
	border: $tp-panelBorderColor solid 1px;
	border-radius: 10px;

	.title {
		float: left;
		width: $iconNameSize;
		height: $iconTitleHeight;
		padding: 2px 3px;
		overflow: hidden;

		.portKey {
			float: right;
			color: $tp-panelGrayColor;
			font-size: 10px;
			margin: 2px;
		}

		.portModule {
			float: left;
			color: $tp-panelGrayColor;
			font-size: 10px;
			margin: 2px;
			max-width: 55px;
			white-space:nowrap;
			overflow: hidden;
		}

	}

	.iconDock {
		float: left;
		width: $iconNameSize;
		height: $iconDockHeight;
		overflow: hidden;
		font-size: 13px;

		.buttons {
			display: block;
		}

		.arrows {
			display: inline;
		}

		.ib_off, .ib_on {
			width: $tp-iconSize;
			height: $tp-iconSize;
			margin: 7px calc((#{$iconContainerSize} - 34px) / 2);
		}

		.valueBlock {
			text-align: center;

			.vicon {
				margin: 0 3px 0 0;
			}

			.valueModifier {
			}

			.startButton, .stopButton, .middleButton {
				display: inline-block;
				background-color: $tp-panelBg;
				color: black;
				padding: 5px 6px;
				text-align: center;
				font-size: 10px;
				line-height: 15px;
			}
			.startButton {
				border-radius: 8px 0 0 8px;
				padding-left: 8px;
			}
			.stopButton {
				border-radius: 0 8px 8px 0;
				padding-right: 8px;
			}
			.sel {
				background-color: $tp-active;
				color: $tp-active-inverted;
			}


		}
	}

	.iconName {
		text-align: center;
		width: $iconNameSize;
	}
}

.iconContainer {
	height: $iconContainerSize;
}

.iconSmallContainer {
	height: $iconSmallHeight;
}

.commandsEditor {
	.buttons {
		text-align: center;
		width: 100%;

		button {
			margin: 2px;
		}
	}

	.commandEditor {
		margin: 2px 0;
	}
}

.iconContextList {
	.iconSmallContainer {
		margin: 2px calc((#{$contextWidth} - 2 * (#{$iconContainerSize} + 2px)) / 4);
	}
}

$tp-portHeight: 16px;

.moduleContainer, .userContainer {
	float: left;
	border-radius: 10px;
	font-size: 12px;

	.title {
		float: left;
		width: 100%;
		height: 25px;
		padding: 2px 6px;
		overflow: hidden;

		.portCount {
			float: right;
			margin: 2px;

			.type {
				font-weight: normal;
				color: $tp-fontDark;
			}

			.count {
				font-weight: bold;
				color: $tp-highlighted;
				margin-left: 4px;
			}

			.fa-regular {
				font-size: 8px;
				color: $tp-fontDark;
			}
		}

		.moduleType {
			float: left;
			color: $tp-fontDark;
			margin: 2px;
			font-weight: bold;
		}
	}

	.blockName {
		font-weight: bold;
		font-size: 13px;
		color: $tp-fontDark;
		padding: 3px 6px;
	}

	.ports {
		float: left;
		width: $tp-moduleWidth;
		// height: $tp-moduleHeight - 25px;
		padding-bottom: 10px;
		overflow: auto;
		text-align: left;

		.port-item {
			margin: 1px 2px;
			border-radius: 4px;

			text-align: left;
			overflow: hidden;
			height: $tp-portHeight;
			line-height: $tp-portHeight;

			span {
				display: block;
				float: left;
				height: $tp-portHeight;
			}

			span.inverted {
				color: $tp-fontSelectedDark;
			}

			.key {
				font-family: monospace;
				padding-left: 3px;
				width: $keySize;
				text-align: center;
			}

			.value {
				width: 50px;
				color: $tp-highlighted;
				padding-left: 3px;
			}

			.name {
				width: 144px;
				overflow: hidden;
				text-wrap: nowrap;
			}
		}
	}

	.parameters {
		float: left;
		text-align: left;
		width: 100%;
		padding: 0 5px 5px 5px;
		overflow: auto;

		label {
			display: block;
			margin-top: 2px;
			width: 100%;

			input {
				width: 100%;
			}
			select {
				width: 100%;
			}
		}
	}
}
.user {
	.userContainer {
		float: left;
	}
}

.user, .sectionBox {
	.userContainer {
		margin: 5px;
		width: $tp-moduleWidth;
		height: $userModuleHeight;
		// height: $tp-moduleHeight;
		overflow: hidden;
		border: $tp-panelGrayColor 1px solid;

		.userContainerIcon {
			line-height: $userModuleHeight;
			font-size: 50px;
			text-align: center;
			cursor: pointer;
		}

		p {
			margin: 4px 8px;

			label {
				width: 60px;
				display: inline-block;
				font-style: italic;
				text-align: right;
				padding-right: 5px;
			}

			span {
				font-style: normal;
			}
			.name {
				font-weight: bold;
				font-style: normal;
			}
		}

		.buttons {
			text-align: center;

			button {
				font-size: 13px;
				margin: 2px;
			}

			span {
				font-size: 13px;
				line-height: 20px;
				width: 20px;
				margin: 2px;
			}

			span.clickable {
				color: $color-highlighted;
			}
		}
	}
	.sel {
		border: $tp-borderDark 1px solid;

		.sel {
			background-color: $tp-panelBg;
		}
	}
	textarea {
		width: 100%;
		height: auto;
		border: $tp-panelBorderColor solid 1px;
		border-radius: 5px;
		padding: 3px 5px;
		background-color: $color-white;
	}
}

.active-module {
	.title {
		.moduleType {
			color: $tp-active;
		}
	}
}

.iconSelected {
	background-color: $tp-selected;
	border-color: $tp-borderDark;

	.title {
		color: $tp-fontSelectedDark;

		.portKey {
			color: $tp-fontSelectedDark;
		}

		.portModule {
			color: $tp-fontSelectedDark;
		}
	}
}

html, body, #TouchPanel, .fullHeight {
	height: 100%;
}

#content {
	color: #000;
}

$tp-blockPadding: 10px;
$tp-portsSize: $keySize + $tp-portHeight;
$tp-portsSizeWithName: $tp-portsSize + $iconNameSize + 6px;
$tp-blockSize: 2*$tp-portsSize + $iconContainerSize + 2*$tp-blockPadding + 2px;
$tp-blockSizeWithName: 2*$tp-portsSizeWithName + $iconContainerSize + 2*$tp-blockPadding + 2px;

.blocks {
	.logic-block {
		width: $tp-blockSizeWithName;
		margin: 5px;
		padding: $tp-blockPadding;
		border-radius: 5px;
		float: left;
		min-height: 120px;
		background-color: $tp-panelBg;
	}

	.logic-block, .logic-box-fragment {

		.key {
			color: $tp-panelColor;
			font-family: monospace;
			width: $keySize;
			text-align: center;
		}
		.name {
			padding: 0 3px;
			overflow: hidden;
		}
		.inputPorts, .outputPorts {
			float: left;
			width: $tp-portsSizeWithName;
			margin-top: 8px;
			height: 80px;
			overflow: auto;

			.port {
				overflow: hidden;
			}

			span {
				display: block;
				height: $tp-portHeight;
			}

			span.small-icon {
				font-size: $tp-portHeight;
			}
		}
		.inputPorts {
			text-align: left;

			span {
				float: left;
			}
		}

		.outputPorts {
			text-align: right;

			span {
				float: right;
			}
		}

		.fieldPorts {
			margin-top: 4px;
			width: 100%;
			height: 50px;
			float: left;
			overflow: auto;
		}

		.port {
			border-bottom: 1px $tp-panelBorderColor solid;
		}

		.port, .field {
			font-size: 12px;
			// height: $tp-portHeight;
			line-height: $tp-portHeight;

			.description {
				color: $tp-panelColor;
				font-size: 11px;
				padding: 0 5px;
			}
		}

		.fa-regular {
			font-size: 40px;
		}

		.iconDock {
			line-height: 51px;
			text-align: center;
		}
	}

	.sel {
		background-color: $tp-selected;
		color: $tp-selected-inverted;

		.iconContainer, .iconSmallContainer {
			border-color: $tp-fontSelectedDark;

			.title {
				.portKey {
					color: $tp-selected-inverted-gray;
				}
			}

			.iconDock {
				color: $tp-fontSelectedDark;
			}
		}

		.port {
			border-color: $tp-fontSelectedDark;

			.key {
				color: $tp-fontSelectedDark;
			}
		}
	}
}

.controls, .modules, .maintenance, .blocks, .user, .timers {
	padding: 5px 0 0 5px;
	height: 100%;
	overflow: auto;

	.caption {
		padding: 15px 4px;
		font-size: 18px;
		font-family: 'Lato', arial, sans-serif;
		float: left;
		width: 100%;
	}

	.sel {
		background-color: $tp-selected;
	}

	.buttons {
		.sel {
			background-color: $tp-active;
			color: $tp-active-inverted;
		}
	}

	.controlLine {
		height: $iconContainerSize;
	}

}


.maintenance {
	font-family: monospace;
	word-break: break-all;

	.inf {
		color: $tp-fontDark;
		margin: 6px 4px;
		width: auto;
	}
	.dbg {
		color: $tp-active;
		margin: 8px 4px;
		width: auto;
		white-space: pre;
	}
	.err {
		color: $tp-highlighted;
		margin: 6px 4px;
		width: auto;
		white-space: pre;
	}
	.dat {
		background-color: $tp-panelBg;
		padding: 4px 4px;
	}
}

.ace-tomorrow {
	font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
}

.fullModal {
	font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	position: fixed;
	padding: 10px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(238, 238, 238, 0.96);
	overflow: scroll;
	opacity: 98%;
	z-index: 200;

	input {
		padding: 1px 2px;
	}

	.centerDiv {
		text-align: center;

		button {
			margin: 10px;
		}
	}

	button {
		font-size: 13px;
		margin: 3px;

		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
	}

	.modalTextInput {
		width: 300px;
	}

	.smallTextButton {
		font-size: 12px;
		cursor: pointer;
		background-color: $tp-panelIconBackground;
	}

	.smallForm {
		width: 360px;
		border-radius: 10px;
		margin: 0 auto;

		label {
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			display: block;
			text-align: left;
			padding: 5px;
			line-height: 22px;

			input, select {
				width: calc(100% - 80px);
				float: right;
				margin-left: 4px;
			}

			.validation-warning {
				color: $tp-waring;
				display: block;
			}
		}

		label.sel {
			color: $tp-selected;
		}

		.textLeft {
			text-align: left;
		}

		label.clickable {
			padding: 3px;
			border-radius: 5px;
			background-color: $tp-panelIconBackground;;
		}

		.smallFormButton {
			margin: 3px;
			padding: 3px;
			border-radius: 5px;
			background-color: $tp-panelIconBackground;;
		}

		.options {
			font-size: 11px;
			line-height: 40px;
			border-radius: 5px;
			padding: 8px;
			margin: 2px;
			background-color: white;
			cursor: pointer;
		}

		.sel {
			color: $tp-selected-inverted;
			background-color: $tp-control-active;
		}

	}

	.moduleTitle {
		height: 30px;

		.portCount {
			float: right;
			margin: 2px;

			.type {
				font-weight: normal;
				color: $tp-fontDark;
			}

			.count {
				font-weight: bold;
				color: $tp-highlighted;
				margin-left: 4px;
			}

			.fa-regular {
				font-size: 8px;
				color: $tp-fontDark;
			}
		}

		.moduleType {
			float: left;
			color: $tp-fontDark;
			margin: 2px;
			font-weight: bold;
		}
	}

}

.clickable {
	cursor: pointer;
}

.error {
	//border: 1px dashed red;
  background-color: rgba(255,0,0, 0.8);
}

.clickable {
	cursor: pointer;
}

.codeSnippet {
	width: 100%;
	min-height: 60px;
	max-height: 300px;
	border: solid 1px $tp-panelBorderColor;
	background: white;
	padding: 5px;
	margin: 0;
	overflow: auto;
	font-size: 10px;
}

.redKey {
	color: #aa0000;
}

.greenKey {
	color: #008800;
}

.blueKey {
	color: #0000dd;
}

.KeyContainer {
	margin: 1px;
	padding: 0 1px;
	width: 96px;
	border: #c8c8c8 solid 1px;
	color: #797979;
	border-radius: 10px;
	cursor: pointer;

	.key {
		position: relative;
		margin: 2px;
		font-size: 10px;
		top: -2px;
	}
}

.small-icon {
	width: $tp-portHeight;
	height: $tp-portHeight;
	background-size: $tp-portHeight;
	text-align: center;
	font-size: $tp-portHeight;
}

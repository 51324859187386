$tp-selected: #0AC986;
$tp-control-active: rgba(7, 159, 106, 0.9);
$tp-control-hot: #d51c18;
$tp-control-cold: #0A86C9;
$tp-waring: #d95c5c;
$tp-selected-inverted: #fff;
$tp-selected-inverted-gray: #c0f0e1;
$tp-active: #0A86C9;
$tp-active-inverted: #fff;
$tp-highlighted: #C90A0A;
$tp-panelBg: #eee;
$tp-iconBg: white;
$tp-panelBorderColor: #c8c8c8;
$tp-panelColor: #aaaaaa;
$tp-panelIconBackground: #aaaaaa55;
$tp-panelColorOn: #b95a58;
$tp-panelGrayColor: #c8c8c8;
$tp-fontDark: #333333;
$tp-fontSelectedDark: #0a804c;
$tp-borderDark: #0a9864;
$tp-panelIcon: #555555;
$tp-imgPath: "../../template/img";

$tp-buttonMargin: 2px;

$color-highlighted: #0A86C9;
$sliderDisabled: #0F0F0F26;
$panelBorder: #dfe0e4;
$panelBackground: $tp-panelBg;
$color-white: #ffffff;
$slider-handle-size: 20px;
$slider_size: 4px;
$slider_fix: 16px;

$iconNameSize: 94px;
$iconContainerSize: $iconNameSize + 2px;
$iconContainerSizeWithMargin: $iconContainerSize + 2px;
$iconContainerSizeWithBorder: $iconContainerSize + 6px;
$iconContainerTitle: 25px;
$iconContainerSizeWithTitle: $iconContainerSize + $iconContainerTitle + 9px;

$contextWidth: 260px;
$sectionTitleHeight: 28px;
$keySize: 36px;
$iconTitleHeight: calc(18px + (#{$iconContainerSize} - 90px) / 2);
$iconDockHeight: calc(50px + (#{$iconContainerSize} - 90px) / 4);
$iconNameHeight: calc(10px + #{$iconContainerSize} - #{$iconTitleHeight} - #{$iconDockHeight});
$iconSmallHeight: calc(#{$iconTitleHeight} + #{$iconNameHeight} - 10px);

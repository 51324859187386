@import './../../stylesheet/variables';

.Buttons {

	.sel {
		color: $tp-selected;
		background-color: unset;
	}

	.button {
		cursor: pointer;
		margin: 0 2px;
		line-height: 28px;
		font-size: 26px;
		background-color: $tp-panelIconBackground;
		border-radius: 6px;
		padding: 0 10px;

		.fa-regular {
			font-size: 24px;
			margin: 4px 0 0 0;
		}

		.label {
			position: relative;
			top: -4px;
			font-size: 14px;
			margin: 0 5px;
		}
	}
}

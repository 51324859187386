@import './../../stylesheet/variables';

$gray: rgba(red($tp-fontDark), green($tp-fontDark), blue($tp-fontDark), 0.2);

.thermostat {
	text-align: center;
	cursor: pointer;

	svg {
		width: 60px;
		height: 50px;

		.progress-wrapper {
			height: 100vh;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

		rect.heating {
			fill: #000000;
		}

		path {
			stroke-linecap: round;
			stroke-width: 6;
			fill: none;
		}

		.grey {
			stroke: $gray;
			stroke-width: 2;
		}

		text.temperature {
			font-size: 37px;
		}

		text.corner {
			font-size: 26px;
		}

		text.auto, path.propeller_on {
			fill: $tp-fontDark;
		}

		path.auto {
			stroke: $tp-fontDark;
		}

		text.off, rect.heating_off, path.propeller_off {
			fill: $gray;
		}

		path.off {
			stroke: $gray;
		}

		text.on, rect.heating_on {
			fill: $tp-control-hot;
		}

		path.on {
			stroke: $tp-control-hot;
		}

		text.cool {
			fill: $tp-control-cold;
		}

		path.cool {
			stroke: $tp-control-cold;
		}

		.active {
			stroke-dasharray: 248;
		}
	}


}

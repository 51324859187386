@import '../../../stylesheet/variables';

$containerWidth: 200px;

.filtered-module {
	display: inline-block;
	width: $containerWidth;
	height: $iconContainerSizeWithTitle;
	margin: 1px;
	padding: 0 1px;
	border: $tp-panelGrayColor solid 1px;
	border-radius: 10px;
	cursor: pointer;
}

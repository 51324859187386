@import './../../stylesheet/variables';

.roomSelectBox {

	span {
		font-size: 14px;
		margin: 0 3px;
		padding: 3px;
		background-color: $tp-panelIconBackground;
		border-radius: 5px;
	}

	div {
		float: left;
		font-size: 11px;
		border-radius: 5px;
		padding: 8px;
		margin: $tp-buttonMargin;
		background-color: $tp-iconBg;
		cursor: default;
	}

	div.disabled {
		color: $tp-panelColor;
	}

	div.clickable {
		cursor: pointer;
	}

	.sel {
		background-color: $tp-selected;
	}

	.sel.disabled {
		color: $tp-fontSelectedDark;
	}

	p {
		padding: 10px;
		margin: 0;
		text-align: center;
	}
}

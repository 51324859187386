@import 'variables';

.m-time {

  .showtime {
		margin-top: 20px;
		margin-bottom: 10px;
		color: $color-white;
		text-align: center;
	}

  .separator {
		display: inline-block;
		font-size: 32px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.20);
		width: 32px;
		height: 65px;
		line-height: 65px;
		text-align: center;
	}

  .time-text {
		position: relative;
		left: -10px;
		font-size: 15px;
		color: rgba(0, 0, 0, 0.20);
		margin-top: 5px;
		margin-bottom: 2px;
	}


  .time {
		width: 65px;
		height: 65px;
		display: inline-block;
		font-size: 38px;
		line-height: 65px;
		background-color: $sliderDisabled;
		border-radius: 3px;
		text-align: center;
	}
}

.m-time-active {
	.separator {
		color: $color-highlighted;
	}
	.time-text {
		color: $color-highlighted;
	}
	.time {
		background-color: $color-highlighted;
	}
}

@import './../../../stylesheet/variables';

.ModalIcons {

	.icons {
		font-size: 32px;

		span {
			text-align: center;
			width: 60px;
			margin: 5px;
			padding: 10px;
			cursor: pointer;
		}

		.sel {
			color: $tp-selected;
		}
	}
}


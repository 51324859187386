@import '../../../stylesheet/variables';

.movingLine {
	height: 14px;

	div {
		position: relative;
		top: 5px;
		height: 4px;
		background-color: $panelBorder;
	}
}

.visibleLine div {
	background-color: $color-highlighted;
	border-radius: 2px;
}

.roomName {
	float: left;
}

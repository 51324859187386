@import 'variables';

.sliders {
	padding: 0 10px;
	display: block;
	position: relative;
}

.u-slider, .u-slider-time {
	margin: calc(#{$slider_size} + #{$slider_fix} / 2) 0;
	cursor: pointer;
}


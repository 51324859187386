@import '../../stylesheet/variables';

$timer-width: 230px;
$timer-height: 345px;

$timer-color: rgba(0, 0, 0, 0.33);

.timers {
	.timer-item {
		width: $timer-width;
		height: $timer-height;
		min-height: 120px;
		padding: 3px;
		margin: 5px;
		color: $timer-color;
		background-color: $tp-panelBg;
		border-radius: 5px;
		float: left;

		.title {
			font-weight: bold;
			margin-top: 4px;
			padding: 3px;
			text-align: center;

			span {
				font-size: 18px;
				float: right;
				cursor: pointer;
			}
		}

		.code {
			margin: 0 7px 7px 7px;
			padding: 4px;
			font-size: 9px;
			height: 20px;
			border: 1px solid rgba(0, 0, 0, 0.07);
			overflow: hidden;
		}

		.commands {
			padding: 10px 7px;
		}

		.m-week {
			margin: 0 7px;
		}

		.m-time {
			margin: 0 7px 7px 7px;
		}

		.sel {
			background-color: $tp-selected;
			color: $tp-selected-inverted;
		}
	}

	.active {
		color: $tp-active
	}

	.new-timer {
		line-height: $timer-height;
		font-size: 50px;
		color: $tp-panelIcon;
		text-align: center;
	}
}

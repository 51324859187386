@import './../stylesheet/variables';

/// navigation menu
.nav, .nav-open {
	border-left: 1px solid $tp-panelBorderColor;
	border-bottom: 1px solid $tp-panelBorderColor;
	border-radius: 0 0 0 6px;
	background: $tp-panelBg;
	width: $contextWidth + 11px;
	font-family: 'Lato', arial, sans-serif;
	font-size: 16px;
	line-height: 28px;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 100;
	transform: translate3d(100%, 0, 0);
	transition: all .2s ease-out;

	div {
		list-style: none;
		margin: 0 0 0 0;
		padding: 0;
		transform: translate3d(0, 0, 0);
	}

	a {
		color: $tp-active;
		text-decoration: none;
	}

	.menu-icon {
		color: $tp-active;
		display: block;
		width: 40px;
		position: absolute;
		left: -50px;
		margin-top: 6px;
		top: 0;

		&:before,
		&:after,
		.button-text {
			background: $tp-panelColor;
			border-radius: 3px;
			content: '';
			display: block;
			height: 4px;
			margin: 6px 0;
			transition: all .2s ease-out;
			width: 100%;
		}

		.button-text {
			text-indent: -9999px;
		}
	}

	.menu-items {
		padding: 10px 10px 2px 10px;
		text-align: center;

		.menu-link {
			color: $tp-active;
			display: inline-block;
			width: 100px;
			margin: 10px;
			transform: translate3d(0, 0, 0);
			transition: all .15s ease-out;

			&:hover {
				transform: translate3d(3px, 3px, 0);
			}

		}
	}

	.sel {
		color: $tp-selected;
	}

	a.icon {
		font-size: 40px;
	}
}

.nav-open {
	transform: translate3d(0, 0, 0);

	.menu-icon {
		cursor: pointer;

		&:before {
			transform: rotate(45deg) translate3d(5px, 10px, 0);
		}

		.button-text {
			opacity: 0;
		}

		&:after {
			transform: rotate(-45deg) translate3d(5px, -10px, 0);
		}
	}
}

.menu {
	span {
		font-size: 30px;
		width: 60px;
		line-height: 50px;
		text-align: center;
		color: $tp-panelColor;
	}

	span.sel {
		color: $tp-selected;
	}

	.close {
		float: left;
		width: 16px;
		height: 100px;

		span {
			font-size: 10px;
			line-height: 100px;
			width: 16px;
			color: $tp-active;
		}
	}
}
